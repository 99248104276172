import React from "react";
import { SearchContainer, TodoContainer } from "../../components";

const Todo = () => {
  return (
    <>
      <SearchContainer />
      <TodoContainer />
    </>
  );
};

export default Todo;
